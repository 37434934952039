import { configApi } from '../../http-client.js'

export const getVendors = () => {
	return configApi.get('/api/Vendors')
		.then(response => {
			if (response.status === 204 || !response.data) {
				return { vendors: [], fileInfo: {} }
			}
			return {
				vendors: response.data.vendors.map(vendor => ({
					...vendor,
					selected: vendor.flag === 'In Use',
					unavailable: vendor.flag === 'Not Available'
				})),
				fileInfo: response.data.fileInfo
			}
		})
		.catch(error => {
			console.error('Failed to fetch vendors:', error)
			return { vendors: [], fileInfo: {} }
		})
}

export const putVendors = vendorList => configApi.put('/api/Vendors', vendorList)
