<template>
	<ValidationForm #default="{ handleSubmit }">
		<CookieTemplateLayout
			:header-title="'Vendor Management' | useLabels('Vendor Management')"
			:header-caption="'Manage the vendor list' | useLabels('Manage the vendor list')"
		>
			<template #content>
				<FilterBar
					:search-query.sync="searchQuery"
					search-query-label="Vendor Name"
					hide-brand-filter
					@clearFilters="clearFilters"
				>
					<template #after-filters>
						<Dropdown
							v-model="selectedStatus"
							:items="statusItems"
							label="Status"
							custom-sort
						/>
						<TextField
							v-model="selectedIabId"
							label="IAB ID"
							type="number"
						/>
					</template>
				</FilterBar>
				<SectionCard>
					<template #title>
						Vendors
					</template>
					<template #title-action>
						<div class="vendor-details-container">
							<span class="vendor-details">
								Global Vendor List Version: {{ fileInfo.vendorListVersion ? fileInfo.vendorListVersion : 'N/A' }}
							</span>
							<span class="vendor-details">
								Last Updated: {{ formattedVendorListLastUpdated }}
								<v-tooltip
									v-if="fileInfo.tooltip"
									top
								>
									<template #activator="{ on }">
										<v-icon
											small
											:color="getTooltipColor()"
											v-on="on"
										>
											mdi-information
										</v-icon>
									</template>
									{{ fileInfo.tooltip }}
								</v-tooltip>
							</span>
							<span class="vendor-details">
								Vendor List Last Updated: {{ formattedGVLSSLastUpdated }}
							</span>
						</div>
					</template>
					<template #body>
						<DataTable
							:headers="tableHeaders"
							:items="filteredVendors"
							sort-by="id"
						>
							<template #item.selected="{ item }">
								<Toggle
									:value.sync="item.selected"
									:disabled="!userCanUpdate || item.unavailable"
									@update:value="selectedChanged(item.id, $event)"
								/>
							</template>
						</DataTable>
					</template>
				</SectionCard>
			</template>
			<template #footer>
				<PageActionRow>
					<template #actions>
						<v-spacer />
						<PrimaryActionButton
							v-if="userCanUpdate"
							@click="handleSubmit(submit)"
						>
							Save
						</PrimaryActionButton>
					</template>
				</PageActionRow>
			</template>
		</CookieTemplateLayout>
	</ValidationForm>
</template>
<script>
import { mapGetters } from 'vuex'
import ValidationForm from '../../../../../../../shared/components/validation-form.vue'
import CookieTemplateLayout from '../cookie-template-layout.vue'
import FilterBar from '../../../../../../../shared/components/filter-bar.vue'
import Dropdown from '../../../../../../../shared/components/dropdown.vue'
import TextField from '../../../../../../../shared/components/text-field.vue'
import PrimaryActionButton from '../../../../../../../shared/components/primary-action-button.vue'
import SectionCard from '../../../../../../../shared/components/section-card.vue'
import DataTable from '../../../../../../../shared/components/data-table.vue'
import Toggle from '../../../../../../../shared/components/toggle.vue'
import PageActionRow from '../../../../../../../shared/components/page-action-row.vue'
import { getVendors, putVendors } from '../../../../../../../shared/utils/api/cookies/cookie-vendors.js'
import { COOKIE_VENDORS_FULL_PERMISSIONS } from '../../../../../../../shared/permissions/admin-portal-permissions.js'
import { showSnackbar } from '../../../../../../../shared/state/snackbar.js'

export default {
	components: {
		CookieTemplateLayout,
		ValidationForm,
		FilterBar,
		Dropdown,
		TextField,
		SectionCard,
		DataTable,
		Toggle,
		PrimaryActionButton,
		PageActionRow
	},
	setup () {
		return { showSnackbar }
	},
	data () {
		return {
			searchQuery: null,
			selectedStatus: null,
			selectedIabId: null,
			vendors: [],
			fileInfo: {}
		}
	},
	computed: {
		...mapGetters('auth', ['productAreaPermission']),
		userCanUpdate () {
			return this.productAreaPermission(COOKIE_VENDORS_FULL_PERMISSIONS)
		},
		tableHeaders () {
			return [
				{ value: 'id', text: 'IAB ID', width: '10%' },
				{ value: 'name', text: 'Name', width: '20%' },
				{ value: 'flag', text: 'Status', width: '20%' },
				{ value: 'selected', text: 'Selected', width: '10%' }
			]
		},
		statusItems () {
			return [
				{ text: 'All', value: null },
				{ text: 'Available', value: 'Available' },
				{ text: 'In Use', value: 'In Use' },
				{ text: 'Not Available', value: 'Not Available' }
			]
		},
		filteredVendors () {
			return this.vendors.filter(({ name, flag, id }) => {
				let check = true
				if (this.searchQuery) check = name.toLowerCase().includes(this.searchQuery.toLowerCase())
				if (this.selectedIabId) check = check && id.toString().includes(this.selectedIabId)
				if (this.selectedStatus) check = check && flag.toLowerCase().includes(this.selectedStatus.toLowerCase())
				return check
			})
		},
		formattedVendorListLastUpdated () {
			return this.formatDate(this.fileInfo.vendorListLastUpdated) // Use the simplified formatDate method
		},
		formattedGVLSSLastUpdated () {
			return this.fileInfo.gvlssLastUpdated ? this.formatDate(this.fileInfo.gvlssLastUpdated) : 'N/A' // Use the simplified formatDate method
		}
	},
	created () {
		this.getVendors()
	},
	methods: {
		clearFilters () {
			this.searchQuery = null
			this.selectedStatus = null
			this.selectedIabId = null
		},
		async getVendors () {
			try {
				const { vendors, fileInfo } = await getVendors()
				this.vendors = vendors
				this.fileInfo = fileInfo
			} catch (error) {
				console.error('Error fetching vendors:', error)
				this.vendors = []
				this.fileInfo = {}
				showSnackbar('Failed to load vendors')
			}
		},
		formatDate (value, isUtc = true) {
			if (!value) return 'N/A'

			let date = new Date(value)
			if (!value.endsWith('Z')) {
				date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()))
			}

			const year = date.getUTCFullYear()
			const month = (date.getUTCMonth() + 1).toString().padStart(2, '0')
			const day = date.getUTCDate().toString().padStart(2, '0')
			const hours = date.getUTCHours().toString().padStart(2, '0')
			const minutes = date.getUTCMinutes().toString().padStart(2, '0')

			return `${year}-${month}-${day} ${hours}:${minutes} UTC`
		},
		getTooltipColor () {
			if (this.fileInfo && typeof this.fileInfo.tooltip === 'string') {
				const tooltipMessage = this.fileInfo.tooltip
				if (tooltipMessage.includes('Neither current nor penultimate version in use')) {
					return 'red'
				} else if (tooltipMessage.includes('Penultimate version in use')) {
					return 'amber'
				} else if (tooltipMessage.includes('The Global Vendor List is more recent than your last Selected Partners List update')) {
					return 'red'
				}
			}
			return ''
		},
		daysSince (date) {
			const now = new Date()
			const timeDiff = now - date
			return timeDiff / (1000 * 60 * 60 * 24)
		},
		selectedChanged (id, value) {
			this.vendors.find(el => el.id === id).selected = value
		},
		async submit () {
			const vendors = this.vendors.filter(({ selected }) => selected).map(vendor => {
				return vendor.id
			})
			const vendorsObject = {
				vendors: vendors
			}
			await putVendors(vendorsObject)
			this.clearFilters()
			showSnackbar('Vendors updated')
			this.getVendors()
		}
	}
}
</script>
<style scoped>
.full-width-container {
  overflow-x: auto;
  min-width: 100%;
}
.vendor-details {
  font-size: 0.75em;
  font-weight: bold;
}
.vendor-details-container .vendor-details {
	margin-right: 0.5rem;
}

.vendor-details-container .vendor-details:last-child {
	margin-right: 0;
}
</style>
